import Checkbox from '@material-ui/core/Checkbox';
import React from 'react';

import { UserPaymentPlanOption } from '../../../generated/graphql';
import { PlanOptionRadio } from './Radio/Radio';

interface PaymentPlanOptionsProps {
  userPlanData: Array<UserPaymentPlanOption>;
  paymentPlanOption: UserPaymentPlanOption | undefined;
  setPaymentPlanOption: (method: UserPaymentPlanOption) => void;
  useCardForRenewals: boolean;
  setUseCardForRenewals: (method: boolean) => void;
}

const PaymentPlanOptions: React.FC<PaymentPlanOptionsProps> = ({
  userPlanData,
  paymentPlanOption,
  setPaymentPlanOption,
  useCardForRenewals,
  setUseCardForRenewals
}) => {
  const defaultOption = userPlanData.find((option) => option.isDefault);

  const planRadios = [...userPlanData]
    .sort((a, b) => {
      if (a.paymentPlan === 'Full Pay') return -1;
      if (b.paymentPlan === 'Full Pay') return 1;
      return 0;
    })
    .map((option, i) => {
      if (!defaultOption && option.paymentPlan === 'Full Pay') {
        return (
          <tr key={i}>
            <td>
              <PlanOptionRadio value={option.paymentPlan} defaultChecked />
            </td>
            <td>{option.paymentPlan}</td>
          </tr>
        );
      } else if (defaultOption && option.isDefault) {
        return (
          <tr key={i}>
            <td>
              <PlanOptionRadio value={option.paymentPlan} defaultChecked />
            </td>
            <td>{option.paymentPlan}</td>
          </tr>
        );
      } else {
        return (
          <tr key={i}>
            <td>
              <PlanOptionRadio value={option.paymentPlan} />
            </td>
            <td>{option.paymentPlan}</td>
          </tr>
        );
      }
    });

  const handlePaymentPlanOptionChange = (
    e: React.FormEvent<HTMLDivElement>
  ) => {
    const method: string = (e.target as HTMLInputElement).value;
    const plan = userPlanData.find((item) => {
      return item.paymentPlan === method;
    });
    if (!plan) {
      throw Error(`cannot find corresponding planId for ${method}`);
    }
    setPaymentPlanOption(plan);
  };

  const fullPayPlan = userPlanData.find((item) => {
    return item.paymentPlan === 'Full Pay';
  });

  if (fullPayPlan === undefined) {
    throw Error('cannot find Full Pay plan');
  }

  if (paymentPlanOption === undefined) {
    if (defaultOption) {
      setPaymentPlanOption(defaultOption);
    } else {
      setPaymentPlanOption(fullPayPlan);
    }
  }

  return (
    <div className="PaymentPlanOptions m-2">
      <table className="payment-options">
        <thead>
          <tr>
            <th className="uppercase text-left" colSpan={2}>
              Payment Plan Options
            </th>
          </tr>
        </thead>
        <tbody onChange={handlePaymentPlanOptionChange}>
          {planRadios}
          <div className="py-12"></div>
        </tbody>
        <tr>
          <td>
            <Checkbox
              style={{ paddingLeft: '0px' }}
              size="small"
              checked={useCardForRenewals}
              color="primary"
              onChange={() => {
                setUseCardForRenewals(!useCardForRenewals);
              }}
            />
          </td>
          <td className="font-bold max-w-xs">
            I consent that this card will be used for all future renewal
            transactions.
          </td>
        </tr>
      </table>
    </div>
  );
};

export default PaymentPlanOptions;
