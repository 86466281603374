import { Option } from 'react-multi-select-component';

import { Scalars } from '../../generated/graphql';

export interface InvoiceInfoState {
  invoiceNumber: string;
  invoiceDate: string;
  quoteNumber: string;
  totalPremium: string;
  tax: string;
  surcharge: string;
  policyFee: string;
  glSurcharge: string;
  imSurcharge: string;
  glPolicyFee: string;
  imPolicyFee: string;
  glPolicyFeeTax: string;
  imPolicyFeeTax: string;
  policyFeeTax: string;

  clientNamedInsured: string;
  clientFirstName: string;
  clientMiddleName: string;
  clientLastName: string;
  clientContactNumber: string;
  clientEmail: string;
  clientAddressStreet: string;
  clientAddressCity: string;
  clientAddressProvince: Scalars['ProvinceState'];
  clientAddressPostalCode: string;

  policyDetailsEffectiveDate: string;
  policyDetailsDescription: Option[];
  policyDetailsNotes: string;
}

export const defaultInvoiceInfo: InvoiceInfoState = {
  invoiceNumber: '',
  invoiceDate: '',
  quoteNumber: '',
  totalPremium: '',
  tax: '',
  surcharge: '',
  policyFee: '',
  glSurcharge: '',
  imSurcharge: '',
  glPolicyFee: '',
  imPolicyFee: '',
  glPolicyFeeTax: '',
  imPolicyFeeTax: '',
  policyFeeTax: '',

  clientNamedInsured: '',
  clientFirstName: '',
  clientMiddleName: '',
  clientLastName: '',
  clientContactNumber: '',
  clientEmail: '',
  clientAddressStreet: '',
  clientAddressCity: '',
  clientAddressProvince: 'Ontario',
  clientAddressPostalCode: '',

  policyDetailsEffectiveDate: '',
  policyDetailsDescription: [],
  policyDetailsNotes: ''
};
